import { RelationTableType } from '@lightdash/common';
import {
    rem,
    type ColorScheme,
    type MantineThemeOverride,
} from '@mantine/core';

export enum ButtonVariant {
    'FILLED' = 'filled',
    'OUTLINED' = 'outlined',
    'OUTLINED_ACCENTED' = 'outlined-accented',
    'SUBTLE' = 'subtle',
    'SUBTLE_ACCENTED' = 'subtle-accented',
    'TRANSPARENT' = 'transparent',
    'FILLED_DESTRUCTIVE' = 'filled-destructive',
    'DEFAULT' = 'default',
    'PRIMARY' = 'primary',
    'UNSTYLED' = 'unstyled',
    'OUTLINED_DESTRUCTIVE' = 'outlined-destructive',
}

export enum FontSizes {
    xs = '12px',
    sm = '14px',
    base = '16px',
    lg = '18px',
}

export enum FontWeights {
    normal = 400,
    medium = 500,
    semibold = 600,
}

export const getMantineThemeOverride = (overrides?: {
    colorScheme?: ColorScheme;
    components?: Partial<MantineThemeOverride['components']>;
}): MantineThemeOverride => ({
    ...overrides,

    focusRing: 'auto',

    spacing: {
        one: rem(1),
        two: rem(2),
        xxs: rem(4),
        xs: rem(8),
        sm: rem(12),
        md: rem(16),
        lg: rem(20),
        xl: rem(24),
        xxl: rem(32),
        '3xl': rem(40),
        '4xl': rem(48),
        '5xl': rem(64),
        '6xl': rem(80),
        '7xl': rem(96),
        '8xl': rem(112),
        '9xl': rem(160),
    },

    radius: {
        rounded: '8px',
        xl: '16px',
        xxl: '24px',
    },

    fontSizes: {
        xs: '12px',
        sm: '14px',
        base: '16px',
        lg: '18px',
    },

    fontFamily: ['Hanken Grotesk', 'sans-serif'].join(', '),

    lineHeight: 1.4,

    cursorType: 'pointer',

    components: {
        Pagination: {
            styles: (theme, _params) => ({
                control: {
                    '&[data-active]': {
                        width: '26px',
                        height: '23px',
                        padding: '3px 9px',
                        borderRadius: theme.radius.xxl,
                        backgroundColor: 'rgb(var(--color-black)/0.06)',
                        color: 'rgb(var(--color-gray-800))',
                        fontSize: theme.fontSizes.sm,
                        '&:hover': {
                            backgroundColor:
                                'rgb(var(--color-black)/0.06) !important',
                            color: 'rgb(var(--color-gray-800)) !important',
                        },
                    },
                    '&:not([data-active])': {
                        width: '26px',
                        height: '23px',
                        padding: '3px 9px',
                        fontSize: theme.fontSizes.sm,
                        borderRadius: theme.radius.xxl,
                        color: 'rgb(var(--color-gray-600))',
                        borderColor: 'rgb(var(--color-black)/0.06)',
                        '&:hover': {
                            color: 'rgb(var(--color-gray-800))',
                            backgroundColor: 'rgb(var(--color-black)/0.02)',
                            borderColor: 'rgb(var(--color-black)/0.02)',
                        },
                    },
                },
                dots: {
                    color: 'rgb(var(--color-gray-800))',
                },
            }),
        },

        TextInput: {
            styles: (theme, _params) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                input: {
                    paddingLeft: '13px',
                    paddingRight: '13px',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    borderRadius: theme.radius.rounded,
                    background: '#FFF',
                    borderWidth: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    fontSize: theme.fontSizes.sm,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset',
                    ':focus-within': {
                        borderColor: 'rgb(var(--color-blu-800))',
                        boxShadow: ' 0px 0px 4px 0px rgba(1, 149, 255, 0.25);',
                    },
                    '::placeholder': {
                        color: 'rgb(var(--color-gray-500))',
                        fontWeight: 400,
                    },
                    color: 'rgb(var(--color-gray-800))',
                    fontWeight: 500,
                    caretColor: 'rgb(var(--color-blu-800))',
                    ':read-only': {
                        background: 'rgb(var(--color-gray-50))',
                        border: 'none',
                        boxShadow: 'none',
                    },
                },
            }),
        },
        Textarea: {
            styles: (theme, _params) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    fontWeight: 500,
                    ':focus': {
                        outline: 'none',
                    },
                    ':focus-within': {
                        borderColor: 'rgb(var(--color-blu-800))',
                        boxShadow: ' 0px 0px 4px 0px rgba(1, 149, 255, 0.25);',
                    },
                    '::placeholder': {
                        color: 'rgb(var(--color-gray-500))',
                        fontWeight: 400,
                    },
                },
            }),
        },
        Input: {
            styles: (theme, _params) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                root: {
                    width: 'fit-content !important',
                    border: 'none',
                },
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    fontWeight: 500,
                    ':focus': {
                        outline: 'none',
                    },
                },
            }),
        },

        NumberInput: {
            styles: (theme, _params) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                root: {
                    width: 'fit-content !important',
                },
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    width: 'fit-content !important',
                    fontWeight: 500,
                    ':focus': {
                        outline: 'none',
                    },
                },
            }),
        },
        FileInput: {
            styles: (theme, _params) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                input: {
                    paddingLeft: '13px',
                    paddingRight: '13px',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    borderRadius: theme.radius.rounded,
                    background: '#FFF',
                    borderWidth: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    fontSize: theme.fontSizes.sm,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset',
                    ':focus-within': {
                        borderColor: 'rgb(var(--color-blu-800))',
                        boxShadow: ' 0px 0px 4px 0px rgba(1, 149, 255, 0.25);',
                    },
                    '::placeholder': {
                        color: 'rgb(var(--color-gray-500))',
                        fontWeight: 400,
                    },
                    color: 'rgb(var(--color-gray-800))',
                    fontWeight: 500,
                    caretColor: 'rgb(var(--color-blu-800))',
                },
            }),
        },

        PasswordInput: {
            styles: (theme, _params) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                input: {
                    paddingLeft: '13px',
                    paddingRight: '13px',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    borderRadius: theme.radius.rounded,
                    background: '#FFF',
                    borderWidth: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    fontSize: theme.fontSizes.sm,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset',
                    'input:focus-within': {
                        boxShadow: ' 0px 0px 0px 0px rgba(1, 149, 255, 0.25);',
                    },
                    'input::placeholder': {
                        color: 'rgb(var(--color-gray-500))',
                        fontWeight: 400,
                    },
                    color: 'rgb(var(--color-gray-800))',
                    fontWeight: 500,
                    caretColor: 'rgb(var(--color-blu-800))',
                },
                visibilityToggle: {
                    marginRight: '4px',
                },
            }),
        },

        ColorInput: {
            styles: (theme, _params) => ({
                label: {
                    // FIXME: this is a hack to fix label position. remove after Blueprint migration is complete
                    marginBottom: theme.spacing.xxs,
                },
            }),
        },
        Select: {
            styles: (_theme, _params, _context) => {
                return {
                    label: {
                        marginBottom: _theme.spacing.xxs,
                        fontSize: _theme.fontSizes.sm,
                        fontWeight: 500,
                        color: 'rgb(var(--color-gray-800))',
                    },
                    root: {
                        width: 'fit-content !important',
                    },
                    input: {
                        borderRadius: '8px',
                        background: 'white',
                        border: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.06)',
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                        fontSize: '14px',
                        color: 'rgb(var(--color-gray-800))',
                        fontWeight: 500,
                        // width: 'fit-content !important',
                        ':focus': {
                            outline: 'none',
                        },
                    },
                    dropdown: {
                        maxWidth: '268px',
                        borderRadius: '8px',

                        // left: 'unset !important',
                    },
                    separator: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    separatorLabel: {
                        fontWeight: 600,
                    },
                    item: {
                        '.selected__item--icon-selected': {
                            display: 'none',
                        },
                        '&[data-selected]': {
                            backgroundColor: 'white',
                            color: 'rgb(var(--color-gray-800))',
                            '.selected__item--icon-selected': {
                                display: 'block',
                            },
                            '&:hover': {
                                backgroundColor: 'white',
                            },
                        },
                    },
                };
            },
        },

        MultiSelect: {
            styles: (theme, _params, _context) => ({
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                root: {
                    width: '100% !important',
                },
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    ':focus': {
                        outline: 'none',
                    },
                },
                dropdown: {
                    left: 'unset !important',
                },
                separator: {
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'white',
                },
                separatorLabel: {
                    fontWeight: 600,
                },
                item: {
                    '&[data-selected]': {
                        backgroundColor: 'rgb(var(--color-gray-400))',
                        color: 'rgb(var(--color-gray-800))',
                        '&:hover': {
                            backgroundColor: 'rgb(var(--color-gray-400))',
                        },
                    },
                },
            }),
        },
        DateTimePicker: {
            styles: (_theme, _param) => ({
                root: {
                    width: '100% !important',
                },
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    ':focus': {
                        outline: 'none',
                    },
                },
                calendar: {
                    height: '15rem',
                },
            }),
        },
        Kbd: {
            styles: (theme, _params) => ({
                root: {
                    borderBottomWidth: theme.spacing.two,
                },
            }),
        },

        Tooltip: {
            defaultProps: {
                withArrow: false,
                offset: -3,
            },
            variants: {
                xs: (theme) => ({
                    tooltip: {
                        fontSize: theme.fontSizes.xs,
                    },
                }),
            },
            styles: (_theme) => ({
                tooltip: {
                    backgroundColor: 'rgb(var(--color-white))',
                    color: 'rgb(var(--color-gray-800))',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    fontWeight: 500,
                    padding: '8px 16px',
                    borderRadius: '8px',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset',
                },
            }),
        },

        Modal: {
            defaultProps: {
                // FIXME: This makes the mantine modals line up exactly with the Blueprint ones.
                // It could be made a less-magic number once we migrate
                // yOffset: 240,
            },
            styles: (theme, _params) => ({
                header: {
                    borderBottom: '2px solid rgb(var(--color-black)/0.04)',
                },
                title: {
                    fontSize: theme.fontSizes.base,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
                content: {
                    borderRadius: theme.radius.xl,
                    maxHeight: '40rem',
                    maxWidth: '100vw',
                },
                body: {
                    padding: '0px !important',
                    paddingBottom: '0px',
                },
            }),
        },
        Checkbox: {
            styles: (_theme, _params) => ({
                input: {
                    borderRadius: '4px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    ':focus': {
                        outline: 'none',
                    },
                    '&:hover': {
                        border: '1px solid',
                        borderColor: 'rgba(1, 149, 255, 1)',
                        boxShadow: '0px 1px 2px 3px rgba(1, 149, 255, 0.15)',
                    },
                },
            }),
        },
        Alert: {
            styles: (_theme, _params) => ({
                title: {
                    // FIXME: This makes the icon align with the title.
                    lineHeight: 1.55,
                },
            }),
        },

        Button: {
            styles: (theme, _params, context) => {
                const baseStyles = {
                    leftIcon: {
                        marginLeft: '-0.25rem',
                        marginRight: '0.25rem',
                    },
                    rightIcon: {
                        marginLeft: '0.25rem',
                        marginRight: '-0.25rem',
                    },
                    label: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100% !important',
                    },
                };
                const commonStyles = {
                    borderRadius: '0.625rem',
                    paddingLeft: '0.75rem',
                    paddingRight: '0.75rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                    height: 'unset',
                    lineHeight: '1.25rem',
                    fontSize: '0.875',
                    fontWeight: 600,
                    "& .section[data-position='left']": {
                        margin: '0px',
                    },
                    "& .section[data-disabled='true']": {
                        backgroundColor:
                            'rgb(var(--color-gray-200)) !important',
                    },
                };
                if (
                    context.variant === ButtonVariant.OUTLINED ||
                    context.variant === ButtonVariant.DEFAULT
                ) {
                    return {
                        ...baseStyles,
                        root: {
                            ...commonStyles,
                            backgroundColor:
                                'rgb(var(--color-white)) !important',
                            color: 'rgb(var(--color-gray-700))',
                            borderColor:
                                'rgb(var(--color-gray-200)) !important',
                            border: '1px solid',
                            ':hover': {
                                backgroundColor:
                                    'rgb(var(--color-gray-50)) !important',
                            },
                        },
                    };
                }
                if (context.variant === ButtonVariant.SUBTLE) {
                    return {
                        ...baseStyles,
                        root: {
                            ...commonStyles,
                            backgroundColor: 'transparent !important',
                            color: 'rgb(var(--color-gray-700))',
                            ':hover': {
                                color: 'rgb(var(--color-gray-800))',
                            },
                        },
                    };
                }
                if (context.variant === ButtonVariant.UNSTYLED) {
                    return {
                        ...baseStyles,
                        root: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            backgroundColor: 'transparent !important',
                            color: 'rgb(var(--color-gray-600))',
                        },
                    };
                }
                if (
                    context.variant === ButtonVariant.OUTLINED_ACCENTED ||
                    context.variant === ButtonVariant.SUBTLE_ACCENTED
                ) {
                    return {
                        ...baseStyles,
                        root: {
                            ...commonStyles,
                            backgroundColor:
                                'rgb(var(--color-white)) !important',
                            color: 'rgb(var(--color-blu-800))',
                            ...(context.variant ===
                                ButtonVariant.OUTLINED_ACCENTED && {
                                borderColor:
                                    'rgb(var(--color-gray-200)) !important',
                                border: '1px solid',
                            }),
                            ':hover': {
                                backgroundColor:
                                    'rgb(var(--color-blu-100)) !important',
                            },
                        },
                    };
                }

                if (context.variant === ButtonVariant.TRANSPARENT) {
                    return {
                        ...baseStyles,
                        root: {
                            ...commonStyles,
                            color: 'rgb(var(--color-gray-700))',
                            ':hover': {
                                backgroundColor:
                                    'rgb(var(--color-gray-800)) !important',
                            },
                        },
                    };
                }
                if (context.variant === ButtonVariant.FILLED_DESTRUCTIVE) {
                    return {
                        ...baseStyles,
                        root: {
                            ...commonStyles,
                            backgroundColor:
                                'rgb(var(--color-halt-800)) !important',
                            color: 'rgb(var(--color-white))',
                            ':hover': {
                                backgroundColor:
                                    'rgb(var(--color-halt-850)) !important',
                            },
                        },
                    };
                }
                if (context.variant === ButtonVariant.OUTLINED_DESTRUCTIVE) {
                    return {
                        ...baseStyles,
                        root: {
                            ...commonStyles,
                            backgroundColor:
                                'rgb(var(--color-white)) !important',
                            color: 'rgb(var(--color-gray-700))',
                            borderColor:
                                'rgb(var(--color-gray-200)) !important',
                            border: '1px solid',
                            fontWeight: FontWeights.semibold,
                            ':hover': {
                                backgroundColor:
                                    'rgb(var(--color-gray-50)) !important',
                            },
                            ':active': {
                                backgroundColor:
                                    'rgb(var(--color-halt-400)) !important',
                                borderColor: 'none !important',
                                color: 'rgb(var(--color-halt-800)) !important',
                            },
                        },
                    };
                }
                return {
                    ...baseStyles,
                    root: {
                        backgroundColor: 'rgb(var(--color-blu-800))',
                        color: 'rgb(var(--color-white))',
                        ...commonStyles,
                        ':hover': {
                            backgroundColor:
                                'rgb(var(--color-blu-900)) !important', // Example hover background color
                        },
                    },
                };
            },
        },
        Accordion: {
            styles: (_theme, _params) => ({
                root: {
                    padding: '10px',
                    backgroundColor: 'white',
                },
            }),
        },
        Menu: {
            styles: (_theme, _params) => ({
                dropdown: {
                    boxShadow:
                        '0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.06)',
                    borderRadius: _theme.radius.rounded,
                },
            }),
        },

        NavLink: {
            styles: (_theme, _params) => ({
                body: {
                    fontWeight: 600,
                    color: 'white !important',
                    fontSize: _theme.fontSizes.sm,
                },
            }),
        },
        Radio: {
            styles: (theme, _params) => ({
                inner: {
                    marginTop: '2px',
                },
                radio: {
                    width: '1rem',
                    height: '1rem',
                    border: '1px solid rgb(var(--color-black)/0.06)',
                },

                label: {
                    fontSize: theme.fontSizes.sm,
                    color: 'rgb(var(--color-gray-800))',
                    paddingLeft: '8px',
                },
            }),
        },
        DateInput: {
            styles: (theme, _params) => ({
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    ':focus': {
                        outline: 'none',
                    },
                },
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
            }),
        },
        TimeInput: {
            styles: (theme, _params) => ({
                input: {
                    borderRadius: '8px',
                    background: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.06)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
                    fontSize: '14px',
                    color: 'rgb(var(--color-gray-800))',
                    ':focus': {
                        outline: 'none',
                    },
                    ':read-only': {
                        background: 'rgb(var(--color-gray-50))',
                        border: 'none',
                        boxShadow: 'none',
                    },
                },
                label: {
                    marginBottom: theme.spacing.xxs,
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    color: 'rgb(var(--color-gray-800))',
                },
            }),
        },

        ScrollArea: {
            variants: {
                primary: (theme) => ({
                    scrollbar: {
                        '&, &:hover': {
                            background: 'transparent',
                        },
                        '&[data-orientation="vertical"] .mantine-ScrollArea-thumb':
                            {
                                backgroundColor: theme.colors.gray['5'],
                            },
                        '&[data-orientation="vertical"][data-state="visible"] .mantine-ScrollArea-thumb':
                            {
                                // When visible, fade in
                                animation: 'fadeIn 0.3s ease-in forwards',
                            },

                        '&[data-orientation="vertical"] .mantine-ScrollArea-thumb:hover':
                            {
                                backgroundColor: theme.fn.darken(
                                    theme.colors.gray['5'],
                                    0.1,
                                ),
                            },
                    },
                    viewport: {
                        '.only-vertical & > div': {
                            display: 'block !important', // Only way to override the display value (from `table`) of the Viewport's child element
                        },
                    },
                }),
            },
        },
        Stepper: {
            styles: (theme) => ({
                stepCompletedIcon: {
                    width: 'inherit',
                    height: 'inherit',
                    borderRadius: 'inherit',
                    textAlign: 'center',
                    border: 'none',
                    fontSize: theme.fontSizes.sm,
                    fontWeight: FontWeights.normal,
                },
                stepIcon: {
                    fontSize: theme.fontSizes.sm,
                    fontWeight: FontWeights.normal,
                    color: 'rgb(var(--color-gray-600))',
                    border: 'none',
                    '&[data-progress="true"]': {
                        backgroundColor: 'rgb(var(--color-blu-800))',
                        color: 'white !important',
                    },
                    '&[data-completed="true"]': {
                        backgroundColor: 'rgb(var(--color-blu-100)) !important',
                        color: 'rgb(var(--color-blu-800)) !important',
                    },
                },
                stepBody: {
                    margin: '6px',
                },
                stepLabel: {
                    fontSize: theme.fontSizes.sm,
                    fontWeight: FontWeights.normal,
                    color: 'rgb(var(--color-gray-600))',
                },
                separator: {
                    display: 'none',
                },
                steps: {
                    gap: '13px',
                },
            }),
        },
        ...overrides?.components,
    },

    globalStyles: (theme) => ({
        'html, body': {
            backgroundColor: theme.colors.gray[0],
        },

        body: {
            fontSize: '14px',
        },

        p: {
            marginBottom: 0,
            marginTop: 0,
        },

        b: {
            fontWeight: 'bold',
        },

        strong: {
            fontWeight: 600,
        },

        /**
         * Markdown colors for dark mode. Does not account for syntax highlighting.
         */
        '[data-color-mode="dark"].wmde-markdown': {
            '--color-canvas-default': theme.black,
            '--color-canvas-subtle': theme.colors.dark[7],
            '--color-border-default': theme.colors.dark[3],
            '--color-border-muted': theme.colors.dark[3],
            '--color-fg-default': theme.white,
            '--color-fg-muted': theme.colors.dark[4],
            '--color-fg-subtle': theme.colors.dark[2],
        },

        '.react-draggable.react-draggable-dragging .tile-base': {
            border: `1px solid ${theme.colors.blue[5]}`,
        },

        '.ace_editor.ace_autocomplete': {
            width: '500px',
        },
        '.ace_editor *': {
            fontFamily:
                "Menlo, 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace",
        },
        '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
        },
    }),
});

//TODO: This is a hack to get the colors to work. We should probably just use the Mantine theme.

interface TableStyles {
    textColorTailwind: string;
    textColor: string;
}

interface GlobalStylesType {
    tableStyles: Record<RelationTableType, TableStyles>;
}

export const GlobalStyles: GlobalStylesType = {
    tableStyles: {
        [RelationTableType.PRIMARY]: {
            textColorTailwind: 'text-blu-800',
            textColor: 'rgb(var(--color-blu-800))',
        },
        [RelationTableType.EVENT]: {
            textColorTailwind: 'text-mustard-800',
            textColor: 'rgb(var(--color-mustard-800))',
        },
        [RelationTableType.RELATED]: {
            textColorTailwind: 'text-green',
            textColor: 'rgb(var(--color-green))',
        },
        [RelationTableType.AUDIENCE]: {
            textColorTailwind: 'text-pink-800',
            textColor: 'rgb(var(--color-pink-800))',
        },
    },
};

export enum PageLoaderColor {
    'GRAY' = 'GRAY',
    'ACCENT' = 'ACCENT',
}
