export type ActionEventField = {
    eventName: string;
    eventLabel: string;
    eventSourceIcon: React.ReactNode;
    eventSourceLabel: string;
    eventSource: string;
    eventType: JourneyEventType;
};

export enum JourneyEventType {
    INTERNAL_EVENT = 'internalEvent',
    WHITELISTED_EVENT = 'whitelistedEvent',
    JOURNEY_PARAM_EVENT = 'journeyParamEvent',
}
