import { type JourneyAction } from '@lightdash/common';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback } from 'react';
import { useNodeData } from '../../ReactFlow/Nodes/useNodeData';
import { ControlPanel } from '../../types';
import ActionStack from '../Actions/ActionStack';
import BlockConfigContainer from './BlockConfigContainer';

interface BlockConfigPanelProps {
    nodeId: string;
}

const BlockConfigPanel: React.FC<BlockConfigPanelProps> = ({ nodeId }) => {
    const {
        updateNodeActionConfig,
        closeControlPanel,
        updateNodeConfig,
        openControlPanel,
        deleteNode,
    } = useJourneyBuilderContext((context) => context.actions);
    const { journeyNodeData, icon } = useNodeData(nodeId);
    const onActionChange = useCallback(
        (updatedAction: JourneyAction) => {
            if (journeyNodeData) {
                updateNodeActionConfig(journeyNodeData.id, updatedAction);
            }
        },
        [journeyNodeData, updateNodeActionConfig],
    );

    const onDescriptionChange = useCallback(
        (description: string) => {
            if (!nodeId) return;
            updateNodeConfig({
                nodeId,
                nodePayload: { description },
            });
        },
        [nodeId, updateNodeConfig],
    );

    const handleChangeButtonClick = useCallback(() => {
        if (!nodeId) return;
        openControlPanel({
            type: ControlPanel.BLOCKS_LIST,
            reactFlowNodeId: nodeId,
        });
    }, [nodeId, openControlPanel]);

    const handleDeleteButtonClick = useCallback(() => {
        if (!nodeId) return;
        deleteNode(nodeId);
    }, [nodeId, deleteNode]);

    if (!nodeId || !journeyNodeData) return null;

    return (
        <BlockConfigContainer
            nodeData={journeyNodeData}
            icon={icon}
            onDescriptionChange={onDescriptionChange}
            onBlockClose={closeControlPanel}
            showModifyButtons={true}
            onChangeButtonClick={handleChangeButtonClick}
            onDeleteButtonClick={handleDeleteButtonClick}
        >
            <ActionStack
                actions={journeyNodeData.actions}
                onActionChange={onActionChange}
                nodeId={journeyNodeData.id}
            />
        </BlockConfigContainer>
    );
};

export default React.memo(BlockConfigPanel);
