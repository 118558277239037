import Delay, {
    type DelayActionProps,
} from '@components/Journeys/Builder/ControlPanel/Actions/Delay';
import Filter, {
    type FilterActionProps,
} from '@components/Journeys/Builder/ControlPanel/Actions/Filter';
import SendCampaign, {
    type CampaignDetailsChangeProps,
    type SendCampaignProps,
} from '@components/Journeys/Builder/ControlPanel/Actions/SendCampaign';
import {
    ActionType,
    PeriodType,
    type ApiAction,
    type FilterAction,
    type HttpConfig,
    type JourneyAction,
    type JourneyFilterGroup,
    type SendMessageAction,
    type SetAction,
    type SplitAction,
    type WaitAction,
    type WaitUntilAction,
} from '@lightdash/common';
import type React from 'react';
import CallAPIAction, { type CallAPIActionProps } from './CallAPI';
import WaitUntil, { type WaitUntilProps } from './WaitUntil';

// Map ActionType to Specific Action Types
type ActionTypeMap = {
    [ActionType.WAIT]: WaitAction;
    [ActionType.SPLIT]: SplitAction;
    [ActionType.FILTER]: FilterAction;
    [ActionType.SET]: SetAction;
    [ActionType.API]: ApiAction;
    [ActionType.WAIT_UNTIL]: WaitUntilAction;
    [ActionType.SEND_MESSAGE]: SendMessageAction;
};

// Map ActionType to Component Props
export type ActionComponentProps = {
    [ActionType.WAIT]: DelayActionProps;
    [ActionType.SPLIT]: DelayActionProps;
    [ActionType.FILTER]: FilterActionProps;
    [ActionType.SET]: DelayActionProps;
    [ActionType.API]: CallAPIActionProps;
    [ActionType.WAIT_UNTIL]: WaitUntilProps;
    [ActionType.SEND_MESSAGE]: SendCampaignProps;
};

// Type for the Mapper
type Mapper = {
    [K in ActionType]: {
        component: React.ComponentType<ActionComponentProps[K]>;
        getProps: (
            action: ActionTypeMap[K],
            onActionChange: (payload: JourneyAction) => void,
            nodeId: string,
        ) => ActionComponentProps[K];
    };
};

// Define the actionMapper with the correct types
export const actionMapper: Mapper = {
    [ActionType.WAIT]: {
        component: Delay,
        getProps: (action, onActionChange) => {
            return {
                selectedDuration: action.config.time,
                onDurationChange: (
                    duration: number,
                    granularity: PeriodType,
                ) => {
                    onActionChange({
                        type: ActionType.WAIT,
                        config: {
                            time: duration,
                            responseConfig: action.config.responseConfig,
                        },
                        uiConfig: {
                            timeGranularity: granularity,
                        },
                    });
                },
                granularity:
                    action.uiConfig?.timeGranularity ?? PeriodType.HOUR,
                durationOptions: [
                    PeriodType.MINUTE,
                    PeriodType.HOUR,
                    PeriodType.DAY,
                    PeriodType.WEEK,
                ],
            } as DelayActionProps;
        },
    },

    [ActionType.SPLIT]: {
        component: Delay,
        getProps: () => {
            // Define and return props for the SPLIT action
            // Add any specific callback connections here
            return {
                // Your props here
            } as DelayActionProps;
        },
    },
    [ActionType.FILTER]: {
        component: Filter,
        getProps: (action, onActionChange, nodeId) => {
            return {
                filters: action.config.filterConfig,
                nodeId: nodeId,
                setFilters: (value: JourneyFilterGroup) => {
                    onActionChange({
                        type: ActionType.FILTER,
                        config: {
                            filterConfig: value,
                            responseConfig: action.config.responseConfig,
                        },
                        uiConfig: {
                            ...action.uiConfig,
                            transientEventFilterTable: undefined,
                        },
                    });
                },
                actionTitle: undefined,
                transientEventFilterTable:
                    action.uiConfig?.transientEventFilterTable,
                setTransientEventFilterTable: (value: string) => {
                    onActionChange({
                        type: ActionType.FILTER,
                        config: {
                            filterConfig: { and: [] },
                            responseConfig: action.config.responseConfig,
                        },
                        uiConfig: {
                            ...action.uiConfig,
                            transientEventFilterTable: value,
                        },
                    });
                },
                setFilterDataSchema: undefined,
            } as FilterActionProps;
        },
    },
    [ActionType.SET]: {
        component: Delay,
        getProps: () => {
            // Define and return props for the SET action
            // Add any specific callback connections here
            return {
                // Your props here
            } as DelayActionProps;
        },
    },

    [ActionType.API]: {
        component: CallAPIAction,
        getProps: (action, onActionChange, nodeId) => {
            return {
                nodeId: nodeId,
                payloadMapper: action.config.apiConfig.payloadMapper,
                httpConfig: action.config.apiConfig.httpConfig,
                responseConfig: action.config.responseConfig,
                onPayloadMapperChange: (value: string) => {
                    onActionChange({
                        type: ActionType.API,
                        config: {
                            ...action.config,
                            apiConfig: {
                                ...action.config.apiConfig,
                                payloadMapper: value,
                            },
                        },
                    });
                },
                onHttpConfigChange: (value: HttpConfig) => {
                    onActionChange({
                        type: ActionType.API,
                        config: {
                            ...action.config,
                            apiConfig: {
                                ...action.config.apiConfig,
                                httpConfig: value,
                            },
                        },
                    });
                },
                onResponsePayloadMapperChange: (value: Record<string, any>) => {
                    onActionChange({
                        type: ActionType.API,
                        config: {
                            ...action.config,
                            responseConfig: value,
                        },
                    });
                },
            } as CallAPIActionProps;
        },
    },
    [ActionType.WAIT_UNTIL]: {
        component: WaitUntil,
        getProps: (action, onActionChange, nodeId) => {
            return {
                filters: action.config.eventConfig.filterConfig,
                nodeId: nodeId,
                setFilters: (value: JourneyFilterGroup) => {
                    onActionChange({
                        type: ActionType.WAIT_UNTIL,
                        config: {
                            ...action.config,
                            eventConfig: {
                                ...action.config.eventConfig,
                                filterConfig: value,
                            },
                        },
                    });
                },
                setEvent: ({ eventName, eventSource }) => {
                    onActionChange({
                        type: ActionType.WAIT_UNTIL,
                        config: {
                            ...action.config,
                            eventConfig: {
                                ...action.config.eventConfig,
                                filterConfig: undefined,
                                eventName,
                                eventSource,
                            },
                        },
                        uiConfig: {
                            ...action.uiConfig,
                        },
                    });
                },
                selectedDuration: action.config.time ?? 0,
                onDurationChange: (
                    duration: number,
                    granularity: PeriodType,
                ) => {
                    onActionChange({
                        type: ActionType.WAIT_UNTIL,
                        config: {
                            ...action.config,
                            time: duration,
                            responseConfig: action.config.responseConfig,
                        },
                        uiConfig: {
                            timeGranularity: granularity,
                        },
                    });
                },
                granularity:
                    action.uiConfig?.timeGranularity ?? PeriodType.HOUR,
                durationOptions: [
                    PeriodType.MINUTE,
                    PeriodType.HOUR,
                    PeriodType.DAY,
                    PeriodType.WEEK,
                ],
                event: {
                    eventName: action.config.eventConfig.eventName,
                    eventSource: action.config.eventConfig.eventSource,
                },
            } as WaitUntilProps;
        },
    },
    [ActionType.SEND_MESSAGE]: {
        component: SendCampaign,
        getProps: (action, onActionChange, nodeId) => {
            return {
                communicationDetails:
                    action.config.messageConfig.communicationDetails,
                templateDetails: action.config.messageConfig.templateDetails,
                onCampaignDetailsChange: ({
                    communicationDetails,
                    templateDetails,
                }: CampaignDetailsChangeProps) => {
                    onActionChange({
                        type: ActionType.SEND_MESSAGE,
                        config: {
                            messageConfig: {
                                communicationDetails,
                                templateDetails,
                            },
                            responseConfig: {},
                        },
                    });
                },
                nodeId: nodeId,
            } as SendCampaignProps;
        },
    },
};
