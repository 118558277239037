import { type JourneyNodeEnum } from '@components/Journeys/Builder/types';
import { Box, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import AddNodeButton from '../AddNodeButton';

type NodeContainerProps = {
    nodeType: JourneyNodeEnum;
    isFocused: boolean; //This flag is used to determine if the node is focused via keyboard
    isSelected: boolean; //This flag is used to determine if the node is selected via mouse click
    nodeBanner: React.ReactNode | undefined;
    onAddNodeClick?: () => void;
};

const NodeContainer: React.FC<React.PropsWithChildren<NodeContainerProps>> = ({
    children,
    isFocused,
    isSelected,
    onAddNodeClick,
    nodeBanner,
}) => {
    const getBorder = useMemo(() => {
        if (isFocused) {
            return 'border-blu-800 outline-blu-800/20 outline-dashed outline-offset-[-4px]';
        }
        if (isSelected) {
            return 'border-blu-800 !shadow-[0px_0px_0px_2px_rgba(1,149,225,0.40)]';
        }
        return 'border-gray-200 hover:border-gray-400';
    }, [isFocused, isSelected]);

    return (
        <Stack className="items-center gap-0">
            <Stack
                className={`w-[16.25rem] min-h-[4.25rem] border bg-white rounded-xl cursor-pointer flex items-center justify-center shadow-[0px_0px_3px_0px_rgba(0,0,0,0.04)] gap-0 ${getBorder}`}
            >
                <Box
                    className={`w-full !border-inherit !rounded-t-[inherit] transition-all duration-700 ease-in-out overflow-hidden ${
                        nodeBanner ? 'max-h-96' : 'max-h-0'
                    }`}
                >
                    {nodeBanner}
                </Box>

                <Box className="w-full p-3">{children}</Box>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    isConnectable={false}
                    className="w-0 h-0 !bg-transparent !border-none"
                />
                <Handle
                    type="target"
                    position={Position.Top}
                    isConnectable={false}
                    className="w-0 h-0 !bg-transparent !border-none"
                />
            </Stack>

            {onAddNodeClick && (
                <Stack className="items-center gap-0.5">
                    <Box className="w-px h-4 mt-1 bg-gray-400"></Box>
                    <AddNodeButton onAddNodeClick={onAddNodeClick} />
                </Stack>
            )}
        </Stack>
    );
};

export default React.memo(NodeContainer);
