import Checkbox from '@components/common/Checkbox';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useLocale } from '@hooks/useLocale';
import {
    FieldType,
    RelationTableType,
    type FilterableDimension,
} from '@lightdash/common';
import { Box, Group } from '@mantine/core';
import { CaretRight, Nut } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import {
    getTextColor,
    renderTableIcon,
    type FieldListItemProps,
} from './utils';

export const FieldListBoxWrapper: React.FC<
    React.ComponentProps<typeof Box> & {
        checked: boolean;
        disableHover?: boolean;
        children: React.ReactNode;
    }
> = ({ checked, disableHover = false, children, ...props }) => {
    return (
        <Box
            className={`flex items-center px-2 rounded-md justify-between ${
                checked
                    ? 'bg-blu-800/6'
                    : disableHover
                    ? ''
                    : 'hover:bg-blu-800/6 '
            }`}
            sx={(_theme) => ({
                '&:hover': {
                    "& input[type='checkbox']": {
                        border: '1px solid',
                        borderColor: 'rgba(1, 149, 255, 1)',
                        boxShadow: '0px 1px 2px 3px rgba(1, 149, 255, 0.15)',
                    },
                },
            })}
            {...props}
        >
            {children}
        </Box>
    );
};

const FieldListItem: React.FC<FieldListItemProps> = ({
    item,
    checked,
    showCheckbox,
    disabled,
    disableHover,
    showFieldSource = true,
}) => {
    const { t } = useLocale();

    const tableType = item?.tableType;

    const tableLabel = useMemo(() => {
        if (item.tableType === RelationTableType.AUDIENCE) {
            return t('filter_property_type.in_audience');
        }
        return (item as FilterableDimension)?.tableLabel ?? item?.table;
    }, [item, t]);

    const itemLabel = useMemo(() => {
        if (item.tableType === RelationTableType.AUDIENCE) {
            return (item as FilterableDimension).tableLabel;
        }
        return item?.label;
    }, [item]);

    const fieldIcon = useMemo(() => {
        if (item.tableType === RelationTableType.AUDIENCE) {
            return null;
        }
        return <FieldIcon type={item?.type} strokeWidth={2.5} />;
    }, [item]);

    return (
        <FieldListBoxWrapper
            checked={checked}
            disableHover={disableHover}
            key={`${item?.table} ${item?.name}`}
        >
            <Box className={`flex !items-center gap-1`}>
                {showCheckbox && (
                    <Checkbox disabled={disabled} checked={checked} size="xs" />
                )}

                {showFieldSource && (
                    <Group className="gap-1 !items-center flex flex-nowrap">
                        <Box className="!p-0">
                            <div className="flex items-center">
                                <div className="flex items-center justify-center gap-10 rounded-md ">
                                    {renderTableIcon(item?.tableType)}
                                </div>
                                <span
                                    className={`text-sm pl-1.5 font-medium max-w-[120px] truncate`}
                                    style={{
                                        color: getTextColor(tableType),
                                    }}
                                >
                                    {tableLabel && (
                                        <TextWithTooltip text={tableLabel} />
                                    )}
                                </span>
                            </div>
                        </Box>
                        <CaretRight />
                    </Group>
                )}

                {item?.fieldType === FieldType.METRIC && (
                    <Nut color={'rgb(var(--color-purple)'} />
                )}
                {fieldIcon}
                <Box className={`max-w-[120px] truncate`}>
                    {itemLabel && (
                        <TextWithTooltip
                            text={itemLabel}
                            className="!m-0 py-2 rounded text-sm text-gray-800 font-medium"
                        />
                    )}
                </Box>
            </Box>
        </FieldListBoxWrapper>
    );
};

export default React.memo(FieldListItem);
