import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useLocale } from '@hooks/useLocale';
import {
    RelationTableType,
    type CompiledRelation,
    type FilterableField,
} from '@lightdash/common';
import { Button, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretDown } from '@phosphor-icons/react';
import { ButtonVariant } from '../../../mantineTheme';
import FieldListItem, { FieldListBoxWrapper } from './FieldListItem';
import {
    convertFieldsIntoPropertySelectListType,
    renderTableIcon,
} from './FieldListItem/utils';
import { type FieldWithSuggestions } from './FiltersProvider';
import { isFieldAudience } from './utils';

const ActiveFieldDisplay: React.FC<{
    activeField: FilterableField | undefined;
    isEditMode: boolean;
    showFieldSource: boolean;
    relation: CompiledRelation | undefined;
}> = ({ activeField, isEditMode, showFieldSource, relation }) => {
    const { t } = useLocale();

    if (!activeField) {
        return (
            <Flex className="items-center justify-between !w-full">
                <Text className="text-sm font-normal text-gray-500">
                    {t('filter_rule_form.empty_property_select_label')}
                </Text>
                <CaretDown
                    color={'rgb(var(--color-gray-500)'}
                    weight={'regular'}
                />
            </Flex>
        );
    }

    const isAudienceField = relation && isFieldAudience(activeField, relation);
    if (isAudienceField && activeField.table) {
        const tableName = relation?.tables[activeField.table].label;
        return (
            <Flex justify="space-between" align={'center'}>
                <FieldListBoxWrapper
                    checked={false}
                    disableHover={isEditMode}
                    key={`${activeField?.table}_${activeField?.name}`}
                >
                    <Group className="flex items-center gap-1 flex-nowrap">
                        <Flex className="flex-row items-center justify-center gap-10 rounded-md ">
                            {renderTableIcon(RelationTableType.AUDIENCE)}
                        </Flex>
                        <TextWithTooltip
                            text={tableName}
                            className="!m-0 py-2 rounded text-sm text-gray-800 font-medium"
                        />
                    </Group>
                </FieldListBoxWrapper>
            </Flex>
        );
    }

    return (
        <Flex justify="space-between" align={'center'}>
            <FieldListItem
                item={activeField}
                disabled={!isEditMode}
                showCheckbox={false}
                checked={false}
                disableHover
                showFieldSource={showFieldSource}
            />
            <CaretDown color={'rgb(var(--color-gray-600)'} weight={'regular'} />
        </Flex>
    );
};

interface FilterRuleFormPropertySelectProps {
    fields: FieldWithSuggestions[];
    onSubmit: (items: FieldWithSuggestions[]) => void;
    isEditMode: boolean;
    activeField: FilterableField | undefined;
    relation: CompiledRelation | undefined;
    withinPortal?: boolean;
    minTargetButtonWidth?: string;
    showFieldSource?: boolean;
}
const FilterRuleFormPropertySelect: React.FC<
    FilterRuleFormPropertySelectProps
> = ({
    fields,
    onSubmit,
    isEditMode,
    activeField,
    relation,
    withinPortal = false,
    minTargetButtonWidth,
    showFieldSource = true,
}) => {
    const [opened, { open, close }] = useDisclosure();
    const { t } = useLocale();
    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={convertFieldsIntoPropertySelectListType(fields)}
                showGroup={false}
                headerRightSection={null}
                onSubmit={(field: FieldWithSuggestions[]) => {
                    if (!field[0]) return;
                    onSubmit(field);
                    close();
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={item.isChecked ?? false}
                            showCheckbox={false}
                            disabled={item.isDisabled ?? false}
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                withinPortal={withinPortal}
                targetButton={
                    <Button
                        variant={ButtonVariant.UNSTYLED}
                        className="px-2 w-fit"
                        style={{
                            borderRadius: '8px',
                            background: 'white',
                            border: '1px solid',
                            borderColor: 'rgba(0, 0, 0, 0.06)',
                            boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                            fontSize: '14px',
                            color: 'rgb(var(--color-gray-600))',
                            width: 'wrap-content !important',
                            cursor: !isEditMode ? 'auto' : 'pointer',
                            minWidth: minTargetButtonWidth
                                ? minTargetButtonWidth
                                : '10rem',
                        }}
                        disabled={!isEditMode}
                    >
                        <ActiveFieldDisplay
                            activeField={activeField}
                            isEditMode={isEditMode}
                            showFieldSource={showFieldSource}
                            relation={relation}
                        />
                    </Button>
                }
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
            />
        </>
    );
};
export default FilterRuleFormPropertySelect;
