import { useLocale } from '@hooks/useLocale';
import { Divider, Group, Stack } from '@mantine/core';
import { ClockCountdown } from '@phosphor-icons/react';
import React from 'react';
import ActionTitle from '../ActionTitle';
import BaseTriggerEventFilter from '../BaseTriggerEventFilter';
import DelayAction, { type DelayActionProps } from '../Delay';
import { type FilterActionProps } from '../Filter';

export interface WaitUntilProps extends FilterActionProps, DelayActionProps {
    setEvent: ({
        eventName,
        eventSource,
    }: {
        eventName: string;
        eventSource: string;
    }) => void;
    event: {
        eventName: string;
        eventSource: string;
    };
}

const WaitUntil: React.FC<WaitUntilProps> = ({
    filters,
    nodeId,
    setFilters,
    selectedDuration,
    onDurationChange,
    granularity,
    durationOptions,
    setEvent,
    event,
}) => {
    const { t } = useLocale();

    return (
        <Stack className="w-[35vw]">
            <Stack className="gap-2">
                <ActionTitle
                    title={t('journey_builder.wait_until_filter_action_title')}
                />
                <BaseTriggerEventFilter
                    nodeId={nodeId}
                    eventConfig={event}
                    setEvent={(eventName, eventSource) => {
                        setEvent({
                            eventName,
                            eventSource,
                        });
                    }}
                    filters={filters}
                    setFilters={setFilters}
                />
            </Stack>
            <Divider className="border-t-gray-200" />
            <DelayAction
                selectedDuration={selectedDuration}
                onDurationChange={onDurationChange}
                granularity={granularity}
                durationOptions={durationOptions}
                actionTitle={
                    <Group className="gap-1">
                        <ClockCountdown color={'rgb(var(--color-gray-500))'} />
                        <ActionTitle title={'Timeout after...'} />
                    </Group>
                }
                withLeftPadding={false}
            />
        </Stack>
    );
};

export default React.memo(WaitUntil);
