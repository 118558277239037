import SkeletonLoader from '@components/common/SkeletonLoader';
import JourneyFilters from '@components/Journeys/Builder/JourneyFilters';
import { useJourneySchemaFields } from '@components/Journeys/Builder/useJourneySchemaFields';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import {
    getFilterRulesFromGroup,
    type FilterGroup,
    type JourneyDataSchema,
    type JourneyFilterGroup,
} from '@lightdash/common';
import { Group, Stack } from '@mantine/core';
import { Subtract } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionTitle from '../ActionTitle';

export interface FilterActionProps {
    filters: JourneyFilterGroup;
    nodeId: string;
    setFilters: (value: JourneyFilterGroup) => void;
    actionTitle: React.ReactNode | undefined;
    setFilterDataSchema: ((value: JourneyDataSchema) => void) | undefined; //Info: This is used to set the journey data schema for this filter action
}

const FilterAction: React.FC<FilterActionProps> = ({
    filters,
    nodeId,
    setFilters,
    actionTitle,
    setFilterDataSchema,
}) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { convertSchema } = useJourneySchemaFields();

    const { mutateAsync, isLoading: isJourneyNodeParamsLoading } =
        useGetJourneyNodeParams(nodeId);
    // State to store the journey data schema
    const [journeyDataSchema, setJourneyDataSchema] =
        useState<JourneyDataSchema>();

    const getJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayload;
        if (!config) return;
        const result = await mutateAsync(journeyPayload);
        setJourneyDataSchema(result);
        convertSchema(result);
        if (setFilterDataSchema) {
            setFilterDataSchema(result);
        }
    }, [journeyPayload, mutateAsync, convertSchema, setFilterDataSchema]);

    useEffect(() => {
        void getJourneyNodeParams();
    }, [getJourneyNodeParams]);

    const filterRules = useMemo(() => {
        return getFilterRulesFromGroup(filters as FilterGroup);
    }, [filters]);

    if (isJourneyNodeParamsLoading) {
        return (
            <Stack className="gap-4 w-[30vw]">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }

    return (
        <Stack className="w-[30vw]">
            {actionTitle ? (
                <>{actionTitle}</>
            ) : (
                <>
                    <Group className="gap-1">
                        <Subtract color={'rgb(var(--color-gray-500))'} />
                        <ActionTitle
                            title={t('journey_builder.filter_action_title')}
                        />
                    </Group>
                </>
            )}

            {/* Restricting to filter rules for now. No multiple filter groups are allowed */}
            {journeyDataSchema && (
                <JourneyFilters
                    journeyDataSchema={journeyDataSchema}
                    filterRules={filterRules}
                    isEditMode={true}
                    onChange={(value) => {
                        //FIXME: This is a temporary solution to update the filters.
                        // We need to update the filters to match the journey filter group type
                        setFilters({
                            and: value,
                        } as unknown as JourneyFilterGroup);
                    }}
                    filters={{ dimensions: filters as FilterGroup }} // Typecasting is needed because the filter rules are the same as the journey filter group
                    setFilters={(values) => {
                        setFilters(values.dimensions as JourneyFilterGroup);
                    }}
                    projectUuid={projectUuid}
                    nodeId={nodeId}
                />
            )}
        </Stack>
    );
};

export default React.memo(FilterAction);
