import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider';
import SkeletonLoader from '@components/common/SkeletonLoader';
import JourneyEventFilters from '@components/Journeys/Builder/JourneyFilters/EventFilters';
import { type JourneyEventFilterConfig } from '@components/Journeys/Builder/types';
import { useJourneySchemaFields } from '@components/Journeys/Builder/useJourneySchemaFields';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import {
    getFilterRulesFromGroup,
    JourneyTableType,
    type FilterGroup,
    type Filters,
    type JourneyDataSchema,
    type JourneyFilterGroup,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface BaseTriggerEventFilterProps {
    nodeId: string;
    eventConfig: JourneyEventFilterConfig;
    setEvent: (eventSource: string, eventName: string) => void;
    filters: JourneyFilterGroup | undefined;
    setFilters: (filters: JourneyFilterGroup) => void;
}

const BaseTriggerEventFilter: React.FC<BaseTriggerEventFilterProps> = ({
    nodeId,
    eventConfig,
    setEvent,
    filters,
    setFilters,
}) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { journeyPayload, journeyEvents, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const [journeyDataSchema, setJourneyDataSchema] =
        useState<JourneyDataSchema>();

    const { convertSchema, fieldsWithSuggestions } = useJourneySchemaFields();

    const allFields = useMemo(
        () => Object.values(fieldsWithSuggestions),
        [fieldsWithSuggestions],
    );

    const { mutateAsync, isLoading: isJourneyNodeParamsLoading } =
        useGetJourneyNodeParams(nodeId);

    const getJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayload;
        if (!config) return;
        const result = await mutateAsync(journeyPayload);

        //Info: Filtering of tables to keep only those of type "EVENT" since all triggers in Journey Builder are of type EVENT
        result.tables = Object.fromEntries(
            Object.entries(result.tables).filter(
                ([, table]) => table.type === JourneyTableType.EVENT,
            ),
        );
        convertSchema(result);
        setJourneyDataSchema(result);
    }, [journeyPayload, mutateAsync, convertSchema]);

    useEffect(() => {
        void getJourneyNodeParams();
    }, [getJourneyNodeParams]);

    const filterRules = useMemo(() => {
        return getFilterRulesFromGroup(filters as FilterGroup);
    }, [filters]);

    if (isJourneyNodeParamsLoading) {
        return (
            <Stack className="gap-4">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }

    if (!journeyDataSchema || !journeyEvents) return null;

    return (
        <FiltersProvider projectUuid={projectUuid}>
            <Stack>
                {/* Restricting to filter rules for now. No multiple filter groups are allowed */}
                <JourneyEventFilters
                    journeyEvents={journeyEvents}
                    filterRules={filterRules}
                    isEditMode={isEditable}
                    onChange={() => {}}
                    filters={{ dimensions: filters } as Filters}
                    setFilters={(values) => {
                        setFilters(values.dimensions as JourneyFilterGroup);
                    }}
                    projectUuid={projectUuid}
                    eventConfig={eventConfig}
                    allFields={allFields}
                    setEventConfig={(event) => {
                        setEvent(event.eventName, event.eventSource);
                    }}
                    journeyDataSchema={journeyDataSchema}
                    nodeID={nodeId}
                />
            </Stack>
        </FiltersProvider>
    );
};

export default React.memo(BaseTriggerEventFilter);
