import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '@components/common/Select/PropertySelect/type';
import TruncatedText from '@components/common/TruncateAtMiddleText';
import {
    DimensionType,
    JourneyTableType,
    type JourneyDataSchema,
    type JourneyDimension,
    type JourneyEventMapperSchema,
    type JourneyNode,
    type JourneyTable,
} from '@lightdash/common';
import { Group } from '@mantine/core';
import {
    CaretRight,
    CursorClick,
    Scan,
    User,
    UsersThree,
} from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { type Edge, type Node } from 'reactflow';
import {
    getNodeLocationToFilter,
    getOriginNodeMetadata,
} from '../ControlPanel/Actions/Filter/utils';
import { type JourneyNodeData } from '../types';
import { JourneyEventType } from './types';

export enum JourneyEventGroupType {
    ALL_EVENTS = 'ALL_EVENTS',
    JOURNEY_PARAMS = 'JOURNEY_PARAMS',
}

export type JourneyProperty = JourneyDimension &
    AddditionalPropertySelectListProps & {
        source: string;
    };

export type JourneyEvent = JourneyEventMapperSchema &
    AddditionalPropertySelectListProps;

type PropertySelectAccumulator<T, K extends string = string> = {
    [key: string]: PropertySelectListType<T, K>;
};

const getGroupLabel = (key: JourneyTableType): string => {
    switch (key) {
        case JourneyTableType.EVENT:
            return 'In this journey';
        case JourneyTableType.PRIMARY:
            return 'Profile';
        case JourneyTableType.AUDIENCE:
            return 'Audience';
        default:
            return '';
    }
};

const getGroupIcon = (key: JourneyTableType): React.ReactNode => {
    switch (key) {
        case JourneyTableType.EVENT:
            return <Scan color="rgb(var(--color-gray-800))" />;
        case JourneyTableType.PRIMARY:
            return <User color="rgb(var(--color-gray-800))" />;
        case JourneyTableType.AUDIENCE:
            return <UsersThree color="rgb(var(--color-gray-800))" />;
        default:
            return null;
    }
};

const getJourneyFilterGroupItems = (
    table: JourneyTable,
    tableName: string,
    nodeID: string,
    nodes: Node<JourneyNodeData>[],
    edges: Edge[],
    journeyNodes: JourneyNode[],
): JourneyProperty[] => {
    if (!table) return [];

    if (table.type === JourneyTableType.EVENT) {
        const nodeLocation = getNodeLocationToFilter(
            nodeID,
            nodes,
            table.nodeId ?? '',
            edges,
        );
        const originNodeMetadata = getOriginNodeMetadata(
            table.nodeId ?? '',
            journeyNodes,
            nodeLocation,
            'rgb(var(--color-gray-500))',
        );

        const items = Object.values(table.dimensions).map((dimension) => ({
            ...dimension,
            table: tableName,
            source: dimension.table,
            subGroupKey: tableName,
            subGroupLabel: (
                <Group className="gap-1 font-medium">
                    {originNodeMetadata.eventSourceIcon}
                    <Group className="gap-1">
                        {originNodeMetadata.eventSourceLabel}
                        <CaretRight
                            color="rgb(var(--color-gray-500))"
                            size={12}
                        />
                        <CursorClick
                            color="rgb(var(--color-gray-500))"
                            size={12}
                        />
                        <TruncatedText
                            className="text-sm text-gray-500"
                            text={table.label}
                            maxWidth={100}
                        />
                    </Group>
                </Group>
            ),
            groupKey: JourneyEventGroupType.JOURNEY_PARAMS,
        }));

        return items;
    }
    return Object.values(table.dimensions).map((dimension) => ({
        ...dimension,
        source: tableName,
        groupKey: JourneyEventGroupType.JOURNEY_PARAMS,
    }));
};

/**
 * Custom hook to process journey data schema and convert it into a property select list type.
 *
 * @param journeyDataSchema - The schema containing journey data.
 * @returns An array of property select list types.
 */

interface UseJourneyPropertiesProps {
    journeyDataSchema: JourneyDataSchema | undefined;
    nodes: Node<JourneyNodeData>[];
    edges: Edge[];
    nodeID: string;
    journeyNodes: JourneyNode[];
}

export const useJourneyProperties = ({
    journeyDataSchema,
    nodes,
    edges,
    nodeID,
    journeyNodes,
}: UseJourneyPropertiesProps): PropertySelectListType<JourneyProperty>[] => {
    const [properties, setProperties] = useState<
        PropertySelectListType<JourneyProperty>[]
    >([]);

    useEffect(() => {
        const hierarchy = [
            JourneyTableType.EVENT,
            JourneyTableType.PRIMARY,
            JourneyTableType.AUDIENCE,
        ];

        let dataFields: PropertySelectAccumulator<JourneyProperty> = {};
        if (journeyDataSchema) {
            dataFields = Object.keys(journeyDataSchema.tables).reduce<
                PropertySelectAccumulator<JourneyProperty>
            >((acc, tableKey) => {
                const table = journeyDataSchema.tables[tableKey];
                const key = table.type;
                const groupLabel = getGroupLabel(key);
                const groupIcon = getGroupIcon(key);

                if (key) {
                    if (acc[key] === undefined) {
                        acc[key] = {
                            groupKey: key,
                            groupLabel: groupLabel,
                            groupIcon: groupIcon,
                            items: getJourneyFilterGroupItems(
                                table,
                                tableKey,
                                nodeID,
                                nodes,
                                edges,
                                journeyNodes,
                            ),
                        };
                    } else {
                        acc = {
                            ...acc,
                            [key]: {
                                ...acc[key],
                                items: [
                                    ...acc[key].items,
                                    ...getJourneyFilterGroupItems(
                                        table,
                                        tableKey,
                                        nodeID,
                                        nodes,
                                        edges,
                                        journeyNodes,
                                    ),
                                ],
                            },
                        };
                    }
                }

                return acc;
            }, {} as PropertySelectAccumulator<JourneyProperty>);
        }

        const sortedProperties = hierarchy
            .filter((key) => dataFields[key] !== undefined)
            .map((key) => dataFields[key]);

        setProperties(sortedProperties);
    }, [edges, journeyDataSchema, journeyNodes, nodeID, nodes]);

    return properties;
};

/**
 * Custom hook to process journey data schema and convert it into a property select list type for event properties.
 *
 * @param journeyDataSchema - The schema containing journey data.
 * @param eventTableSource - The event source field inside journeyDataSchema Tables
 * @returns An array of property select list types.
 */
export const useJourneyEventProperties = (
    journeyDataSchema: JourneyDataSchema,
    eventTableSource: string,
): PropertySelectListType<JourneyProperty>[] => {
    const [properties, setProperties] = useState<
        PropertySelectListType<JourneyProperty>[]
    >([]);

    useEffect(() => {
        if (!journeyDataSchema || !eventTableSource) return;

        const table = Object.values(journeyDataSchema.tables).find(
            (t) => t.eventSource === eventTableSource,
        );
        if (!table) return;

        const key = table.type;
        const groupLabel = getGroupLabel(key);
        const groupIcon = getGroupIcon(key);

        const dataFields: PropertySelectAccumulator<JourneyProperty> = {
            [key]: {
                groupKey: key,
                groupLabel: groupLabel,
                groupIcon: groupIcon,
                items: Object.values(table.dimensions).map((dimension) => ({
                    ...dimension,
                    source: eventTableSource,
                })),
            },
        };

        setProperties([dataFields[key]]);
    }, [journeyDataSchema, eventTableSource]);

    return properties;
};

interface UseJourneyEventsProps {
    journeyEvents: JourneyEventMapperSchema[] | undefined;
    journeyDataSchema: JourneyDataSchema | undefined;
    nodeID: string;
    nodes: Node<JourneyNodeData>[];
    edges: Edge[];
    journeyNodes: JourneyNode[];
}

export const useJourneyEvents = ({
    journeyEvents,
    journeyDataSchema,
    nodeID,
    nodes,
    edges,
    journeyNodes,
}: UseJourneyEventsProps): PropertySelectListType<
    JourneyEvent | JourneyProperty,
    JourneyEventGroupType
>[] => {
    const [properties, setProperties] = useState<
        PropertySelectListType<
            JourneyEvent | JourneyProperty,
            JourneyEventGroupType
        >[]
    >([]);

    useEffect(() => {
        let dataFields: PropertySelectAccumulator<
            JourneyEvent | JourneyProperty,
            JourneyEventGroupType
        > = {};

        if (journeyEvents) {
            const key = JourneyEventGroupType.ALL_EVENTS;
            const groupLabel = 'All Events';
            const groupIcon = (
                <CursorClick color="rgb(var(--color-gray-800))" />
            );

            if (dataFields[key] === undefined) {
                dataFields[key] = {
                    groupKey: key,
                    groupLabel: groupLabel,
                    groupIcon: groupIcon,
                    items: [],
                };
            }

            // Separate whitelisted and internal events
            const whitelistedEvents = journeyEvents.filter(
                (event) => !event.isInternal,
            );
            const internalEvents = journeyEvents.filter(
                (event) => event.isInternal,
            );

            // Add whitelisted events first
            whitelistedEvents.forEach((event) => {
                dataFields[key].items.push({
                    ...event,
                    subGroupKey: JourneyEventType.WHITELISTED_EVENT,
                    groupKey: key,
                });
            });

            // Add internal events next
            internalEvents.forEach((event) => {
                dataFields[key].items.push({
                    ...event,
                    subGroupKey: JourneyEventType.INTERNAL_EVENT,
                    groupKey: key,
                });
            });
        }

        if (journeyDataSchema) {
            Object.keys(journeyDataSchema.tables).forEach((tableKey) => {
                const table = journeyDataSchema.tables[tableKey];

                // Skip if table is not an event or if table is the current node
                if (
                    table.type !== JourneyTableType.EVENT ||
                    table.nodeId === nodeID
                )
                    return;
                const key = JourneyEventGroupType.JOURNEY_PARAMS;
                const groupLabel = getGroupLabel(table.type);
                const groupIcon = getGroupIcon(table.type);

                const nodeLocation = getNodeLocationToFilter(
                    nodeID,
                    nodes,
                    table.nodeId ?? '',
                    edges,
                );
                const originNodeMetadata = getOriginNodeMetadata(
                    table.nodeId ?? '',
                    journeyNodes,
                    nodeLocation,
                    'rgb(var(--color-gray-500))',
                );

                const subGroupLabel = (
                    <Group className="gap-1 font-medium">
                        {originNodeMetadata.eventSourceIcon}
                        {originNodeMetadata.eventSourceLabel}
                    </Group>
                );

                if (dataFields[key] === undefined) {
                    dataFields[key] = {
                        groupKey: key,
                        groupLabel: groupLabel,
                        groupIcon: groupIcon,
                        items: [
                            {
                                type: DimensionType.EVENT,
                                label: table.label ?? '',
                                name: table.eventName ?? '',
                                table: tableKey,
                                source: tableKey,
                                subGroupKey: table.nodeId,
                                subGroupLabel: subGroupLabel,
                                groupKey: key,
                            },
                        ],
                    };
                } else {
                    dataFields[key].items.push({
                        type: DimensionType.EVENT,
                        label: table.label ?? '',
                        name: table.eventName ?? '',
                        table: tableKey,
                        source: tableKey,
                        subGroupKey: table.nodeId,
                        subGroupLabel: subGroupLabel,
                        groupKey: key,
                    });
                }
            });
        }

        setProperties(Object.values(dataFields));
    }, [edges, journeyDataSchema, journeyEvents, journeyNodes, nodeID, nodes]);

    return properties;
};
