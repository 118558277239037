import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import {
    FieldType,
    getItemId as getFieldId,
    isDimension,
    RelationTableType,
    type FilterableDimension,
    type FilterableField,
    type FilterRule,
    type JourneyDataSchema,
    type JourneyDimension,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { type JourneyEventFilterConfig } from '../types';
import {
    JourneyEventType as JourneyEventTypeEnum,
    type JourneyEventType,
} from './types';

/**
 * Converts a JourneyDimension to a FilterableField.
 * @param {JourneyDimension} journeyDimension - The JourneyDimension to convert.
 * @returns {FilterableField} - The converted FilterableField.
 * @throws {Error} - If the conversion is not possible.
 */
export function convertToFilterableField(
    journeyDimension: JourneyDimension,
): FilterableField {
    const { type, label, name, table } = journeyDimension;

    const filterableField: FilterableDimension = {
        type,
        label,
        name,
        table,
        fieldType: FieldType.DIMENSION,
        tableLabel: table,
        sql: '',
        hidden: false,
    };

    return filterableField;
}

/**
 * Checks if the provided filter rules are event filters.
 * @param filterRules - The filter rules to check.
 * @param allFields - All fields with suggestions.
 * @returns {boolean} - True if the filter rules are event filters, false otherwise.
 */
export const isJourneyEventFilters = (
    filterRules: FilterRule[] | undefined,
    allFields: FieldWithSuggestions[],
): boolean => {
    // If filterRules is undefined or empty, return false
    if (!filterRules || filterRules.length === 0) {
        return false;
    }

    // Check if any filter rule targets an event dimension
    return filterRules.some((rule) => {
        const fieldTarget = rule.target;
        const selectedField = allFields.find(
            (field) => getFieldId(field) === fieldTarget.fieldId,
        );
        return (
            selectedField &&
            isDimension(selectedField) &&
            selectedField.tableType === RelationTableType.EVENT
        );
    });
};

interface EventTypeArgs {
    eventConfig: Pick<JourneyEventFilterConfig, 'eventName' | 'eventSource'>;
    journeyDataSchema: JourneyDataSchema;
    journeyEvents: JourneyEventMapperSchema[];
}

/**
 * Determines the type of a journey event based on the provided configuration, data schema, and event mappings.
 * @param {EventTypeArgs} args - The arguments containing event configuration, data schema, and event mappings.
 * @param {JourneyEventFilterConfig} args.eventConfig - The configuration for the journey event filter.
 * @param {JourneyDataSchema} args.journeyDataSchema - The schema of the journey data.
 * @param {JourneyEventMapperSchema[]} args.journeyEvents - The list of journey event mappings.
 * @returns {JourneyEventType | undefined} - The type of the journey event, or undefined if not found.
 */
export const getEventType = ({
    eventConfig,
    journeyDataSchema,
    journeyEvents,
}: EventTypeArgs): JourneyEventType | null => {
    if (!eventConfig) {
        return null;
    }

    const { eventName, eventSource } = eventConfig;

    const mappedEvent = journeyEvents.find(
        (event) =>
            event.eventName === eventName && event.source === eventSource,
    );
    if (mappedEvent) {
        return mappedEvent.isInternal
            ? JourneyEventTypeEnum.INTERNAL_EVENT
            : JourneyEventTypeEnum.WHITELISTED_EVENT;
    }

    const mapperJourneyEvent = Object.values(journeyDataSchema.tables).find(
        (table) =>
            table.eventName === eventName && table.eventSource === eventSource,
    );
    if (mapperJourneyEvent) {
        return JourneyEventTypeEnum.JOURNEY_PARAM_EVENT;
    }

    return null;
};
