import { Box, Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

/**
 * Props for the TextWithTooltip component.
 * @property {string} text - The text to display with a tooltip.
 */
interface TextWithTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
}

/**
 * TextWithTooltip component displays text with a tooltip if the text overflows.
 * @param {TextWithTooltipProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
    text,
    ...props
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [isOverflown, setIsOverflown] = useState<boolean>(false);

    useEffect(() => {
        /**
         * Checks if the text overflows its container.
         */
        const checkOverflow = () => {
            const element = ref.current;
            if (element) {
                setIsOverflown(element.scrollWidth > element.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [text]);

    return (
        <Tooltip
            label={text}
            disabled={!isOverflown}
            color="dark"
            position="bottom"
            styles={{
                tooltip: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
        >
            <Box
                ref={ref}
                {...props}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {text}
            </Box>
        </Tooltip>
    );
};
