import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    getFilterRulesFromGroup,
    JourneyTableType,
    type FilterRule,
    type Filters,
    type JourneyDataSchema,
} from '@lightdash/common';
import { Button, Flex, Stack, Text } from '@mantine/core';
import { CaretDown, PlusCircle } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import {
    getNodeLocationToFilter,
    getOriginNodeMetadata,
} from '../ControlPanel/Actions/Filter/utils';
import { useJourneySchemaFields } from '../useJourneySchemaFields';
import FilterSelectWrapper from './FilterSelectWrapper';
import JourneyActiveEventField from './JourneyActiveEventField';
import JourneyEventPropertySelector from './JourneyEventPropertySelector';
import JourneyFilterRuleForm from './JourneyFilterRuleForm';
import JourneyPropertySelector from './JourneyPropertySelector';
import { JourneyEventType, type ActionEventField } from './types';
import { type JourneyProperty } from './useJourneyProperties';
import { convertToFilterableField } from './utils';

/**
 * Props for the JourneyFilters component.
 */
interface JourneyFiltersProps {
    /** The node parameters schema of the selected node in the journey. */
    journeyDataSchema: JourneyDataSchema;

    /** The list of filter rules that are currently applied to the selected node in the journey. */
    filterRules: FilterRule[];

    /** Indicates if the component is in edit mode. */
    isEditMode: boolean;

    /** Function called when the filter rules are changed. */
    onChange: (value: FilterRule[]) => void;

    /** The list of filters that are currently applied to the selected node in the journey. */
    filters: Filters;

    /** Function called when the filters are changed. */
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;

    /** The project UUID. */
    projectUuid: string;

    /** The ID of the selected node. */
    nodeId: string;
}

/**** INFO *****
    JourneyFilters components is a wrapper around the FilterGroupForm component.
    It caters multiple filter groups and multiple filter rules.

    1. To render Simple Filter Group: https://www.figma.com/design/Y89jAFoieVCVviLXdVhyji/%F0%9F%8F%9D%EF%B8%8F-Journeys-0?node-id=1272-89432&m=dev
        - Filter Action will have a single filter rule inside the filter group.
        - The property selector is based on the Customer360 schema.

    2. To render Journey Event Filter: https://www.figma.com/design/Y89jAFoieVCVviLXdVhyji/%F0%9F%8F%9D%EF%B8%8F-Journeys-0?node-id=1272-89367&m=dev
        - These are the events emitted by journey nodes. The UI of the event field shows where the event is emitted from.
        - Properties can be added to the event field using JourneyEventPropertySelector component.
****/

const JourneyFilters: React.FC<JourneyFiltersProps> = ({
    journeyDataSchema,
    filterRules,
    isEditMode,
    onChange,
    filters,
    setFilters,
    projectUuid,
    nodeId,
}) => {
    const { t } = useLocale();
    const { nodes, edges, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { fieldsWithSuggestions, convertSchema } = useJourneySchemaFields();

    useEffect(() => {
        if (!journeyDataSchema || isEmpty(journeyDataSchema)) return;
        convertSchema(journeyDataSchema);
    }, [convertSchema, journeyDataSchema]);

    const allFields = useMemo(
        () => Object.values(fieldsWithSuggestions),
        [fieldsWithSuggestions],
    );

    const addFieldRule = useCallback(
        (field: JourneyProperty) => {
            const filterableField = convertToFilterableField(field);
            const newFilterRule = addFilterRule({
                filters: {},
                field: filterableField,
            });
            console.log('newFilterRule', newFilterRule);
            setFilters(newFilterRule, false, 0);
        },
        [setFilters],
    );

    const onDeleteItem = useCallback(
        (index: number) => {
            onChange([
                ...filterRules.slice(0, index),
                ...filterRules.slice(index + 1),
            ]);
        },
        [filterRules, onChange],
    );

    const onChangeItem = useCallback(
        (item: FilterRule) => {
            onChange([item]);
        },
        [onChange],
    );

    /**
     * Finds the table key by matching the fieldId with the dimension keys in the journeyDataSchema.
     *
     * @param {string} fieldId - The field ID to search for in the dimensions. Example: 'SRT_JOURNEY_EVENT_srt_user_id'
     * @returns {string | undefined} - The table key if a match is found, otherwise undefined.
     */
    const findTableKeyByFieldId = useCallback(
        (fieldId: string): string | undefined => {
            for (const [tableKey, table] of Object.entries(
                journeyDataSchema.tables,
            )) {
                for (const dimensionKey of Object.keys(table.dimensions)) {
                    if (`${tableKey}_${dimensionKey}` === fieldId) {
                        return tableKey;
                    }
                }
            }
            return undefined;
        },
        [journeyDataSchema],
    );

    const journeyEventFilterTable = useMemo(() => {
        if (!filterRules.length) return;
        const field = filterRules[0];
        const fieldId = field.target.fieldId;
        if (!fieldId) return;
        const tableKey = findTableKeyByFieldId(fieldId);
        if (!tableKey) return;
        const tableProperties = journeyDataSchema.tables[tableKey].type;
        if (tableProperties !== JourneyTableType.EVENT) return;
        return tableKey;
    }, [filterRules, findTableKeyByFieldId, journeyDataSchema]);

    //Info: Renders the filter select wrapper and the JourneyPropertySelector component. This is when no filter is applied.
    if (!filterRules.length) {
        return (
            <JourneyPropertySelector
                targetElement={
                    <FilterSelectWrapper isEditMode={isEditMode}>
                        <Flex className="items-center justify-between !w-full">
                            <Text className="text-sm font-normal text-gray-500">
                                {t(
                                    'journey_builder.journey_filters_select_field',
                                )}
                            </Text>
                            <CaretDown
                                color={'rgb(var(--color-gray-500)'}
                                weight={'regular'}
                            />
                        </Flex>
                    </FilterSelectWrapper>
                }
                journeyDataSchema={journeyDataSchema}
                onSubmit={addFieldRule}
                nodes={nodes}
                edges={edges}
                nodeID={nodeId}
                journeyNodes={journeyPayload.config?.nodes ?? []}
            />
        );
    }

    //Info: Renders the Event filters caused by the current Journey. These events are part of JourneyNodeParams
    if (journeyEventFilterTable) {
        const table = journeyDataSchema.tables[journeyEventFilterTable];
        const nodeLocation = getNodeLocationToFilter(
            nodeId,
            nodes,
            table.nodeId ?? '',
            edges,
        );

        const originNodeMetadata = getOriginNodeMetadata(
            table.nodeId ?? '',
            journeyPayload.config?.nodes ?? [],
            nodeLocation,
        );

        const activeEventField: ActionEventField = {
            eventName: table.label,
            eventSource: table.eventSource ?? '',
            eventSourceLabel: originNodeMetadata.eventSourceLabel,
            eventSourceIcon: originNodeMetadata.eventSourceIcon,
            eventLabel: table.label,
            eventType: JourneyEventType.JOURNEY_PARAM_EVENT,
        };

        const isDimensionsEmpty =
            isEmpty(filters.dimensions) ||
            getFilterRulesFromGroup(filters.dimensions).length === 0;

        const addEventProperty = (value: JourneyProperty) => {
            value.table = journeyEventFilterTable;
            const filterableField = convertToFilterableField(value);

            const newFilterRule = addFilterRule({
                filters,
                field: filterableField,
            });
            setFilters(newFilterRule, false, 0);
        };

        return (
            <Stack className="gap-2">
                <JourneyPropertySelector
                    targetElement={
                        <JourneyActiveEventField
                            activeEventField={activeEventField}
                            isEditMode={isEditMode}
                        />
                    }
                    journeyDataSchema={journeyDataSchema}
                    onSubmit={addFieldRule}
                    nodes={nodes}
                    edges={edges}
                    nodeID={nodeId}
                    journeyNodes={journeyPayload.config?.nodes ?? []}
                />

                <Stack
                    className={`border-l-4 border-gray-100 relative ${
                        isDimensionsEmpty ? 'pl-8' : 'pl-12'
                    }`}
                >
                    {!isDimensionsEmpty && filters.dimensions && (
                        <>
                            <FiltersProvider projectUuid={projectUuid}>
                                <FilterGroupForm
                                    allowConvertToGroup={false}
                                    hideLine
                                    hideButtons
                                    conditionLabel="dimension"
                                    filterGroup={filters.dimensions}
                                    fields={allFields}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        setFilters(
                                            {
                                                ...filters,
                                                dimensions: value,
                                            },
                                            false,
                                            0,
                                        )
                                    }
                                    onDelete={() => {}}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={0}
                                    showFieldSource={false}
                                    relation={undefined}
                                />
                            </FiltersProvider>
                        </>
                    )}
                    <JourneyEventPropertySelector
                        targetElement={
                            <Button variant={ButtonVariant.UNSTYLED}>
                                <PlusCircle
                                    color={'rgb(var(--color-gray-700))'}
                                />
                                <Text className="ml-1 text-sm font-semibold text-gray-700">
                                    {t('common.property')}
                                </Text>
                            </Button>
                        }
                        eventField={activeEventField}
                        journeyDataSchema={journeyDataSchema}
                        onSubmit={addEventProperty}
                    />
                </Stack>
            </Stack>
        );
    }

    //Info: Renders the filter rules. This is when a filter is applied. This is for Customer360 filters.
    return (
        <FiltersProvider projectUuid={projectUuid}>
            {/* Filter rules, this is for non-event field. These are the
            Customer360 filters. We don't allow filter groups as of now, hence
            we are not using FilterGroupForm. */}
            <Stack>
                {filterRules.map((item, index) => (
                    <JourneyFilterRuleForm
                        isEditMode={isEditMode}
                        key={item.id}
                        filterRule={item}
                        fields={allFields}
                        onChange={(value) => onChangeItem(value)}
                        onDelete={() => onDeleteItem(index)}
                        onConvertToGroup={undefined}
                        filters={filters}
                        setFilters={setFilters}
                        groupIndex={0}
                        withinPortal={true}
                        journeyDataSchema={journeyDataSchema}
                        onSubmit={addFieldRule}
                        nodes={nodes}
                        edges={edges}
                        nodeID={nodeId}
                        journeyNodes={journeyPayload.config?.nodes ?? []}
                    />
                ))}
            </Stack>
        </FiltersProvider>
    );
};

export default React.memo(JourneyFilters);
