import {
    JourneyNodeEnum,
    type JourneyNodePlaceholderData,
} from '@components/Journeys/Builder/types';
import { Box } from '@mantine/core';
import { Spiral } from '@phosphor-icons/react';

import React from 'react';
import { type NodeProps } from 'reactflow';
import NodeContainer from '../NodeContainer';
import TriggerPlaceholderNode from './TriggerPlaceholderNode';

const PlaceholderNode: React.FC<NodeProps<JourneyNodePlaceholderData>> = ({
    data: { placeHolderType },
}) => {
    if (placeHolderType === JourneyNodeEnum.TRIGGER) {
        return <TriggerPlaceholderNode />;
    }

    return (
        <NodeContainer
            nodeType={JourneyNodeEnum.PLACEHOLDER}
            isFocused={true}
            isSelected={false}
            nodeBanner={undefined}
        >
            <Box className="flex items-center justify-center w-full h-full">
                <Spiral color={'rgb(var(--color-blu-800))'} />
            </Box>
        </NodeContainer>
    );
};

export default PlaceholderNode;
