import InputErrorText from '@components/common/InputErrorText';
import {
    JourneyNodeEnum,
    type JourneyNodeBlockData,
} from '@components/Journeys/Builder/types';
import { useLocale } from '@hooks/useLocale';
import { Box, Divider, Flex, Group, Stack, Text } from '@mantine/core';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { isEmpty } from 'lodash';
import React from 'react';
import { useNodes, type NodeProps } from 'reactflow';
import NodeContainer from './NodeContainer';
import { useNodeData } from './useNodeData';

/**
 *
 * @param {NodeProps<JourneyNodeBlockData>} props - The properties for the node.
 * @returns {JSX.Element | null} The rendered BlockNode component.
 */
const BlockNode: React.FC<NodeProps<JourneyNodeBlockData>> = ({ data }) => {
    const { nodeId } = data;
    const {
        journeyNodeData,
        isLeafNode,
        icon,
        nodeValue,
        hasError,
        nodeBanner,
        subTitle,
    } = useNodeData(nodeId);

    const { addPlaceholderNode } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const { t } = useLocale();

    const reactFlowNodes = useNodes();
    const isNodeSelected =
        reactFlowNodes.find((n) => n.id === nodeId)?.selected ?? false;

    if (!journeyNodeData || isEmpty(journeyNodeData)) return null;
    const isNodeExpanded = journeyNodeData.description || hasError;

    return (
        <NodeContainer
            nodeType={JourneyNodeEnum.BLOCK}
            isFocused={false}
            isSelected={isNodeSelected}
            onAddNodeClick={isLeafNode ? addPlaceholderNode : undefined}
            nodeBanner={nodeBanner}
        >
            <Stack className="w-full gap-0 bg-white">
                <Stack className="items-start w-full gap-0">
                    <Group>
                        <Box className="flex items-center justify-center w-9 h-9 border-base">
                            {icon}
                        </Box>
                        <Stack className="gap-0">
                            <Flex
                                gap={4}
                                align="center"
                                className="text-xs font-medium text-gray-500"
                            >
                                <Text>{journeyNodeData.title}</Text>
                                {subTitle && (
                                    <Flex gap={4} align="center">
                                        {'·'}
                                        <Text>{subTitle}</Text>
                                    </Flex>
                                )}
                            </Flex>
                            <Text className="text-sm font-medium text-gray-800">
                                {nodeValue}
                            </Text>
                        </Stack>
                    </Group>

                    <Box
                        className={`w-full transition-all duration-700 ease-in-out overflow-hidden ${
                            isNodeExpanded ? 'max-h-96' : 'max-h-0'
                        }`}
                    >
                        {isNodeExpanded && (
                            <Divider className="my-3 border-t-gray-200" />
                        )}
                        {journeyNodeData.description && (
                            <Text className="text-xs font-normal text-gray-500">
                                {journeyNodeData.description}
                            </Text>
                        )}
                        {hasError && (
                            <InputErrorText
                                value={t('common.setup_incomplete_error')}
                            />
                        )}
                    </Box>
                </Stack>
            </Stack>
        </NodeContainer>
    );
};

export default React.memo(BlockNode);
